import React from "react";
import styled from "styled-components";
import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import notFoundImage from "../images/product/page_not_found.svg";

const NotFoundPage = () => (
  <Layout>
    <NotFoundWrapper>
      <SEO title="404: Not found" />
      <h1>NOT FOUND</h1>
      <ImageWrapper>
          <StyledImage src={notFoundImage} alt="Not found" />
          <br/>
      </ImageWrapper>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </NotFoundWrapper>
  </Layout>
)

export default NotFoundPage

const NotFoundWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled.img`
  width: 600px;
  @media (max-width: ${props => props.theme.screen.md}) {
    width: 400px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 200px;
  }
`